




































import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  data() {
    return {
      repos: [],
      loading: true,
      error: ''
    }
  },
  methods: {
    open(url: string) {
      window.open(url)
    }
  },
  mounted() {
    const token = this.$store.state.auth.token
    this.error = ''
    axios({
      method: 'get',
      url: 'https://api.github.com/user/repos',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      this.loading = false
      this.repos = response.data
    }).catch((err) => {
      this.loading = false
      this.repos = []
      this.error = 'Error fetching the user repos'
      console.error(err)
    })
  }
})
