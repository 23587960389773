







import { Component, Vue } from 'vue-property-decorator';
import GithubLogin from './components/auth/GithubLogin.vue';
import GithubRepos from './components/GithubRepos.vue'

@Component({
  components: {
    GithubLogin,
    GithubRepos
  },
  mounted() {
    const bearerToken = this.$cookies.get('bearer')
    this.$store.state.auth.token = bearerToken
  }
})
export default class App extends Vue {}
